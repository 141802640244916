import React, { useEffect, useMemo, useState } from 'react';
import {
    addNotesToAlbum,
    createNoteText,
    createRankInAlbum,
    markTimelineItemAsRead,
    moveNote,
    NoteRequests,
    uploadFiles,
    useNotesQuery,
} from 'mushin-redux-store';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import { classWithModifiers } from '../../../../Helpers/css';
import { MuSvg } from '../../../../Components/MuSvg';
import Cloud from '../../../../../assets/icons/cloud-plus.svg';
import { useAppDispatch, useAppSelector } from '../../../../Helpers/hooks';
import { openAlert, openModal } from '../../../../Redux/reducers/modalsSlice';
import useGalleryDragAndDrop from './useGalleryDragAndDrop';
import GalleryImageViewModal from '../ImageViewModal/GalleryImageViewModal';
import GalleryToolbar from '../../../../Components/GalleryToolbar';
import GalleryJustifiedGrid from '../GalleryJustifiedGrid/GalleryJustifiedGrid';
import EmptyZone from '../../../../Components/EmptyZone/EmptyZone';
import Spinner from '../../../../Components/Spinner';
import GallerySelectionBar from '../GallerySelectionBar/GallerySelection';
import { SetSearchParams } from '../../../../Helpers/queryParser';
import { NoteSearchParams } from '../../../../Components/GalleryToolbar/GalleryToolbar';
import { useScrollThreshold } from '../../../../Helpers/effects';
import {
    emptyNoteBucket,
    selectNotesInBucket,
    setNotesMultipleOrigin,
} from '../../../../Redux/reducers/noteBucketSlice';
import { AddModalImages } from '../../../AppModals/Components/AddImagesModal/AddIdeaModal';
import MuIcon from '../../../../Components/MuIcon';
import { GalleryParams } from '../../types';

type Props = React.PropsWithChildren<{
    query: ReturnType<typeof useNotesQuery>;
    searchParams: NoteSearchParams;
    setSearchParams: SetSearchParams<NoteSearchParams>;
    canMove?: boolean;
    isRanked?: boolean;
    scrollToNoteId?: string;
}>;

const GalleryNotes: React.FC<Props> = ({
    query,
    searchParams,
    setSearchParams,
    scrollToNoteId,
    canMove = false,
    isRanked = false,
    children,
}) => {
    const params = useParams<GalleryParams>();

    const [noteIdsUnread, setNoteIdsUnread] = useState<string[]>([]);

    const dragAndDrop = useGalleryDragAndDrop(params);
    const selectedNoteIds = useAppSelector((state) => state.noteBucket.noteIds);

    const scrollRef = useScrollThreshold(query.getMoreResults, { deps: [query.results?.length] });

    const userMentionsQueryParams = useMemo(
        () => ({
            confirmed: true,
            ...(params.album_id ? { album_id: params.album_id } : {}),
            ...(params.projectId ? { project_id: params.projectId } : {}),
        }),
        [params.album_id, params.projectId],
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (params.album_id || params.questionId) {
            NoteRequests.getMovedNotification({
                album_id: params.album_id,
                questionnaire_id: params.questionnaireId,
                question_id: params.questionId,
            }).then((response) => {
                const notificationIds: string[] = [];
                const noteIds: string[] = [];
                response.data.forEach((notification) => {
                    if (!notification.seen) {
                        notificationIds.push(notification._id);
                        noteIds.push(...notification.objects.map((object) => object.object_id));
                    }
                });
                setNoteIdsUnread(noteIds);
                if (notificationIds.length) {
                    timeout = setTimeout(() => {
                        dispatch(markTimelineItemAsRead(notificationIds)).then();
                    }, 1000);
                }
            });
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [dispatch, params.album_id, params.questionId, params.questionnaireId]);

    useEffect(() => {
        if (scrollToNoteId && query.results?.length) {
            setTimeout(() => document.getElementById(scrollToNoteId)?.scrollIntoView(), 500);
        }
    }, [query.results, scrollToNoteId]);

    useEffect(() => {
        // If the current album or gallery changes and note bucket is not empty, deactivate the remove/delete note actions
        if (params) {
            if (selectedNoteIds.length > 0) {
                dispatch(setNotesMultipleOrigin(true));
            } else {
                dispatch(setNotesMultipleOrigin(false));
            }
        }
    }, [params, dispatch, selectedNoteIds.length]);

    const handleImportComputerFiles = (files: File[] | FileList) => {
        dispatch(
            uploadFiles(files, {
                upload_source: 'WEBAPP',
                organization_id: params.organizationId || undefined,
                album_id: params.album_id || undefined,
                questionnaire_id: params.questionnaireId || undefined,
                question_id: params.questionId || undefined,
            }),
        );
        dispatch(openModal('UploadFilesModal'));
    };

    const handleImportFiles = () => {
        dispatch(
            openModal('AddIdeaModal', {
                multipleSelection: true,
                withPdf: true,
                withNotes: !!params.album_id,
                addImages: async (addModalImages: AddModalImages) => {
                    if (addModalImages.notes.length && params.album_id) {
                        await dispatch(
                            addNotesToAlbum(
                                params.album_id,
                                addModalImages.notes.map((note) => note.id),
                            ),
                        );
                    }
                    if (addModalImages.images.length) {
                        handleImportComputerFiles(addModalImages.images);
                    }
                },
                addText: async (label: string) => {
                    dispatch(
                        createNoteText({
                            label,
                            upload_source: 'WEBAPP',
                            organization_id: params.organizationId || undefined,
                            album_id: params.album_id || undefined,
                            questionnaire_id: params.questionnaireId || undefined,
                            question_id: params.questionId || undefined,
                        }),
                    );
                },
            }),
        );
    };

    const selectedNotesCountInCurrentGallery =
        query.results?.filter((noteId) => !!noteId && selectedNoteIds.includes(noteId)).length || 0;

    const allowMove = canMove && (!isRanked || query.params.sort === 'rank' || (isRanked && !query.params.sort));

    const move = async (id: string, settings: { idBefore?: string; idAfter?: string }) => {
        if (isRanked) {
            if (params.album_id) {
                await dispatch(moveNote(id, { album_id: params.album_id, ...settings }));
            }
        } else {
            dispatch(
                openAlert({
                    title: i18n.t('models.notes.createOrder.title'),
                    icon: 'ImageGallery',
                    body: i18n.t('models.notes.createOrder.description'),
                    confirmLabel: i18n.t('global.alert.confirm'),
                    cancelLabel: i18n.t('global.cancel'),
                    withCancelBtn: true,
                    confirmAction: async () => {
                        if (params.album_id) {
                            await dispatch(createRankInAlbum(params.album_id, query.params.sort || '-created_at'));
                            await dispatch(moveNote(id, { album_id: params.album_id, ...settings }));
                            // Arbitrary delay to wait for notes to be indexed with their new rank
                            await new Promise((resolve) => setTimeout(resolve, 5000));
                            setSearchParams({ sort: 'rank' });
                        }
                    },
                }),
            );
        }
    };

    return (
        <div ref={scrollRef} className="mu-app-layout__scroll mu-gallery">
            {query.results && (
                <GalleryImageViewModal
                    noteIds={query.results}
                    loadMoreNotes={query.getMoreResults}
                    userMentionsQueryParams={userMentionsQueryParams}
                />
            )}
            <main
                onDragEnter={dragAndDrop.dragEnterHandler}
                onDragOver={dragAndDrop.dragOverHandler}
                onDrop={dragAndDrop.dropHandler}
                className={classWithModifiers('mu-gallery__content', [{ 'can-drop': dragAndDrop.isDragging }])}
            >
                <div className="mu-gallery-drop-modal" onDragLeave={dragAndDrop.dragLeaveHandler}>
                    <div className="mu-gallery-drop-modal__content">
                        <MuSvg className="mu-gallery-drop-modal__icon" SvgComp={Cloud} />
                        <div className="mu-gallery-drop-modal__caption">
                            <h2 className="mu-gallery-drop-modal__title">
                                {i18n.t('components.noteform.drop_notes_title')}
                            </h2>
                            <div className="mu-gallery-drop-modal__text">
                                {i18n.t('components.noteform.drop_notes_text')}
                            </div>
                        </div>
                    </div>
                </div>
                {children}
                <div className="mu-gallery__content-header">
                    <GalleryToolbar
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        tags={query.aggregations?.hashtags || []}
                        gallery={params.inspirationType}
                    />
                </div>
                <section className="mu-gallery__search-notes">
                    {query.results &&
                        (query.results?.length ? (
                            <GalleryJustifiedGrid
                                noteIds={query.results}
                                canMoveItem={allowMove}
                                moveItem={move}
                                noteIdsUnread={noteIdsUnread}
                            />
                        ) : (
                            !query.loading && (
                                <EmptyZone
                                    title={i18n.t('gallery.no_files.title')}
                                    subTitle={i18n.t('gallery.no_files.desc')}
                                />
                            )
                        ))}
                    {query.loading && <Spinner className="mu-gallery__loader" size="50px" />}
                </section>
            </main>
            {selectedNotesCountInCurrentGallery > 0 && (
                <GallerySelectionBar
                    type="note"
                    count={selectedNotesCountInCurrentGallery}
                    allCount={query.pager?.total_items || 0}
                    empty={() => dispatch(emptyNoteBucket())}
                    selectAll={() => dispatch(selectNotesInBucket(query.results as string[]))}
                />
            )}
            <button type="button" className="mu-gallery__import" onClick={handleImportFiles}>
                <MuIcon svgName="Plus" />
                <br />
                {i18n.t('global.publish')}
            </button>
        </div>
    );
};

export default GalleryNotes;
