import React from 'react';
import { classWithModifiers, Modifiers } from '../../Helpers/css';
import { MuSvg } from '../MuSvg';
import Close from '../../../assets/icons/close.svg';
import Portal from '../Portal/Portal';

type Props = React.PropsWithChildren<{
    handleRemoveModal?: () => void;
    classModifiers?: Modifiers;
}>;

const Modal: React.FC<Props> = ({ classModifiers, handleRemoveModal, children }) => (
    <Portal>
        <div className={classWithModifiers('mu-modal-overlay', classModifiers)}>
            <div className="mu-modal-overlay__handler" onClick={handleRemoveModal} />
            <section className={classWithModifiers('mu-modal', classModifiers)}>
                {children}
                {handleRemoveModal && (
                    <button type="button" className="mu-modal__close-modal" onClick={handleRemoveModal}>
                        <MuSvg SvgComp={Close} />
                    </button>
                )}
            </section>
        </div>
    </Portal>
);

export default Modal;
