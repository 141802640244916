import React, { useState } from 'react';
import MuButton, { ButtonProps } from '../MuButton/MuButton';

type Props = Omit<ButtonProps, 'loading' | 'handleClick'> & {
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const MuButtonWithLoader: React.FC<Props> = ({
    classModifiers = [],
    className,
    label,
    rightIcon,
    icon,
    disabled = false,
    handleClick,
    type = 'button',
}) => {
    const [loading, setLoading] = useState(false);

    const handleClickWithLoading = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setLoading(true);
        await handleClick?.(e);
        setLoading(false);
    };

    return (
        <MuButton
            classModifiers={classModifiers}
            className={className}
            label={label}
            rightIcon={rightIcon}
            icon={icon}
            disabled={disabled}
            handleClick={handleClickWithLoading}
            type={type}
            loading={loading}
        />
    );
};

export default MuButtonWithLoader;
