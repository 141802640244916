import React, { useEffect, useState } from 'react';
import {
    AvailableLocale,
    inviteProfile,
    Profile,
    ProfilesQueryParams,
    QuestionnaireCreatePayload,
    updateProfile,
    useCurrentProject,
    useProfilesQuery,
    useQuestionnairesQuery,
} from 'mushin-redux-store';
import i18n from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useScrollThreshold } from '../../Helpers/effects';
import { useSearchParams } from '../../Helpers/queryParser';
import UsersToolbar, { MembersSearchParams } from '../../Components/UsersToolbar/UsersToolbar';
import { getProfileStatus } from '../../Helpers/users';
import { useAppDispatch } from '../../Helpers/hooks';
import ProfilesGrid from '../../Components/ProfilesGrid/ProfilesGrid';
import MuButton from '../../Components/MuButton';
import { openModal } from '../../Redux/reducers/modalsSlice';
import ProjectLayout from '../ProjectDetail/Components/ProjectLayout/ProjectLayout';
import { createAndInitQuestionnaire } from '../../Helpers/thunkActions';
import SurveyList from '../../Components/SurveysList/SurveyList';

const searchToQueryParams = (searchParams: MembersSearchParams): ProfilesQueryParams => {
    const { status, is_contributor, ...queryParams } = searchParams;

    if (status === 'active') {
        (queryParams as ProfilesQueryParams).active = true;
    } else if (status === 'registered') {
        (queryParams as ProfilesQueryParams).confirmed = true;
    } else if (status === 'invited') {
        (queryParams as ProfilesQueryParams).confirmed = false;
    }

    (queryParams as ProfilesQueryParams).type = is_contributor ? 'contributor' : 'team';

    return queryParams;
};

const searchToDurable = (searchParams: MembersSearchParams) => {
    const params = { ...searchParams };
    delete params.is_contributor;
    return !Object.keys(params).length;
};

const ProjectMembers: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();

    const questionnairesQuery = useQuestionnairesQuery({ project_id: projectId, candidacy: true }, true);

    const [searchParams, setSearchParams] = useSearchParams({ groups: 'array', criteria: 'array' });
    const project = useCurrentProject();
    const profilesQuery = useProfilesQuery(
        {
            project_id: projectId,
            ...searchToQueryParams(searchParams),
        },
        searchToDurable(searchParams),
    );
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const ref = useScrollThreshold(profilesQuery.getMoreResults, { deps: [profilesQuery.results?.length] });

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setParams } = profilesQuery;
    useEffect(() => {
        setParams({ project_id: projectId, ...searchToQueryParams(searchParams) }, searchToDurable(searchParams));
    }, [projectId, searchParams, setParams]);

    if (!project) {
        return null;
    }

    const createQuestionnaire = async (payload?: Omit<QuestionnaireCreatePayload, 'title' | 'project'>) => {
        const questionnaire = await dispatch(createAndInitQuestionnaire({ project_id: projectId, ...payload }));
        navigate(`/projects/current/questionnaires/${questionnaire.id}/edit`);
    };

    const activeTab = searchParams.is_contributor === 'true' ? 'contributors' : 'team';

    return (
        <ProjectLayout project={project} activeTab={activeTab}>
            <div ref={ref} className="mu-project-members">
                <div className="mu-secondary-header">
                    <div>
                        <h2 className="mu-secondary-header__title">{i18n.t(`users.${activeTab}.list`)}</h2>
                        <h3 className="mu-secondary-header__subtitle">{i18n.t(`users.${activeTab}.instruction`)}</h3>
                    </div>
                    <div className="mu-secondary-header__actions">
                        {activeTab === 'contributors' && (
                            <MuButton
                                classModifiers="important"
                                icon="Survey"
                                label={i18n.t('surveys.createCandidacy')}
                                handleClick={() => {
                                    createQuestionnaire({ candidacy: true }).then();
                                }}
                            />
                        )}
                        <MuButton
                            icon="UserAdd"
                            handleClick={() => {
                                dispatch(
                                    openModal(
                                        activeTab === 'contributors'
                                            ? 'InviteContributorProfiles'
                                            : 'InviteProfilesModal',
                                        {
                                            project,
                                            handleSubmit: async (
                                                emails: string[],
                                                locale: AvailableLocale,
                                                candidacyQuestionnaireId,
                                            ) => {
                                                return dispatch(
                                                    inviteProfile(emails, {
                                                        projectId,
                                                        reInvite: false,
                                                        type: activeTab === 'contributors' ? 'contributor' : 'team',
                                                        locale,
                                                        candidacyQuestionnaireId,
                                                    }),
                                                );
                                            },
                                        },
                                    ),
                                );
                            }}
                            label={i18n.t('modals.inviteUsers.title')}
                        />
                    </div>
                </div>
                {activeTab === 'contributors' && questionnairesQuery.results && (
                    <SurveyList
                        className="mu-project-members__questionnaire"
                        surveys={questionnairesQuery.results}
                        getMore={questionnairesQuery.getMoreResults}
                    />
                )}
                <div className="mu-project-members__toolbar">
                    <UsersToolbar
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        contributors={activeTab === 'contributors'}
                        queryParams={profilesQuery.params}
                        removeUsers={async (ids) => {
                            for (const id of ids) {
                                // eslint-disable-next-line no-await-in-loop
                                await dispatch(updateProfile(id, { refused: true }));
                            }
                        }}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        projectId={projectId}
                        getStatus={(user) => getProfileStatus(user)}
                    />
                </div>
                <ProfilesGrid
                    scrollRef={ref}
                    query={profilesQuery}
                    contributors={activeTab === 'contributors'}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    standalone
                    withToolbar
                    getRole={(profile) => profile.role || 'regular'}
                    setRole={(profile, role) => {
                        dispatch(updateProfile(profile.id, { role: role.key as Profile['role'] }));
                    }}
                    roleOptions={[
                        {
                            key: 'owner',
                            icon: 'LockLine',
                            disabled: true,
                            unique: true,
                            fallback: 'admin',
                        },
                        {
                            key: 'admin',
                            icon: 'Settings',
                        },
                        {
                            key: 'regular',
                            icon: 'UserLine',
                        },
                    ]}
                    getStatus={(user) => getProfileStatus(user)}
                />
            </div>
        </ProjectLayout>
    );
};

export default ProjectMembers;
