import React, { useState } from 'react';
import i18n from 'i18next';
import FilterPanelContent, { FilterConfig } from './FilterPanelContent';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import TextIcon from '../TextIcon/TextIcon';
import MuIcon from '../MuIcon';
import { classWithModifiers } from '../../Helpers/css';
import DefaultFilter from './DefaultFilter';

type Props = {
    className?: string;
    filters: {
        [key: string]: FilterConfig;
    };
    defaultFilters?: {
        [key: string]: FilterConfig;
    };
    resetAll: () => void;
};

const FiltersToolbar: React.FC<Props> = ({ className, filters, defaultFilters, resetAll }) => {
    const [chosenFilter, setChosenFilter] = useState<string>();
    const [isOpen, setOpen] = useState(false);

    const activeFilters = [];

    for (const key in filters) {
        if (filters[key].active) activeFilters.push(key);
    }

    const filterKeys = Object.keys(filters);

    return (
        <div className={classWithModifiers('mu-filters-toolbar', null, className)}>
            {defaultFilters &&
                Object.keys(defaultFilters).map((filterKey) => (
                    <DefaultFilter key={filterKey} config={defaultFilters[filterKey]} />
                ))}
            {!!filterKeys.length && (
                <DropdownMenu
                    contentClassName="mu-filters-toolbar__panel"
                    isOpen={isOpen}
                    setIsOpen={(value) => {
                        if (!value) setChosenFilter(undefined);
                        setOpen(value);
                    }}
                    toggle={
                        <span className="mu-filters-toolbar__filter-toggle">
                            {i18n.t('components.filters.action')}
                            <MuIcon className="mu-filters-toolbar__filter-toggle-icon" svgName="Filter" />
                            {!!activeFilters.length && (
                                <div className="mu-filters-toolbar__badge">{activeFilters.length}</div>
                            )}
                        </span>
                    }
                    align="center"
                >
                    {chosenFilter ? (
                        <FilterPanelContent
                            filter={filters[chosenFilter]}
                            requestClose={() => {
                                setChosenFilter(undefined);
                            }}
                        />
                    ) : (
                        <>
                            <div className="mu-filters-toolbar__panel-header">
                                <div className="mu-filters-toolbar__panel-header-title">
                                    {i18n.t('components.filters.title')}
                                </div>
                                {!!activeFilters.length && (
                                    <div className="mu-filters-toolbar__badge">{activeFilters.length}</div>
                                )}
                                <button
                                    className="mu-link mu-filters-toolbar__panel-header-link"
                                    type="button"
                                    onClick={() => {
                                        resetAll();
                                        setOpen(false);
                                    }}
                                >
                                    {i18n.t('global.reset')}
                                </button>
                            </div>
                            {filterKeys.map((filterKey) => (
                                <button
                                    className={classWithModifiers('mu-dropdown-menu__button-item', ['column'])}
                                    key={filterKey}
                                    type="button"
                                    onClick={() => setChosenFilter(filterKey)}
                                >
                                    <TextIcon icon={filters[filterKey].icon} label={filters[filterKey].label} />
                                    {filters[filterKey].value && (
                                        <div className="mu-filters-toolbar__filter-value">
                                            {filters[filterKey].value}
                                        </div>
                                    )}
                                </button>
                            ))}
                        </>
                    )}
                </DropdownMenu>
            )}
        </div>
    );
};

export default FiltersToolbar;
