import React, { useState } from 'react';

import i18n from 'i18next';

import { TemplateSelector } from '../../../Components/BoardComponents/TemplateSelector/TemplateSelector';
import CurrentContent from '../../../Components/BoardComponents/CurrentContent/CurrentContent';
import imageIcon from '../../../../assets/icons/ImageGallery.svg';
import folderFillIcon from '../../../../assets/icons/document-copy.svg';
import moodboardIcon from '../../../../assets/icons/element-4.svg';
import textIcon from '../../../../assets/icons/text-block.svg';
import ElementsInsertion from '../../../Components/BoardComponents/ElementsInsertion/ElementsInsertion';
import AddNotesPanel from '../../../Components/BoardComponents/AddNotesPanel/AddNotesPanel';
import { classWithModifiers } from '../../../Helpers/css';
import MuIcon from '../../../Components/MuIcon';
import { injectLayoutUpdate } from './layoutUpdater';

const tabs = {
    currentContent: {
        Component: CurrentContent,
        icon: imageIcon,
        label: i18n.t('components.boardEditor.currentContent'),
    },
    explorer: {
        Component: AddNotesPanel,
        icon: folderFillIcon,
        label: i18n.t('components.boardEditor.explorer'),
    },
    template: {
        Component: TemplateSelector,
        icon: moodboardIcon,
        label: i18n.t('components.boardEditor.template'),
    },
    insertion: {
        Component: ElementsInsertion,
        icon: textIcon,
        label: i18n.t('components.boardEditor.insert'),
    },
};
const tabsKeys = Object.keys(tabs) as (keyof typeof tabs)[];

type Props = {
    updateLayout: () => void;
};

const LeftPanel: React.FC<Props> = ({ updateLayout }) => {
    const [selectedTab, setSelectedTab] = useState<keyof typeof tabs | null>('explorer');

    const Content = selectedTab && tabs[selectedTab].Component;
    return (
        <div className={classWithModifiers('beClosablePanel', { closed: !selectedTab })}>
            <div className="verticalTabPanel">
                <div className="verticalTabPanel-icons">
                    {tabsKeys.map((key) => {
                        const tab = tabs[key];
                        return (
                            <button
                                key={key}
                                type="button"
                                className={classWithModifiers('verticalTabPanel-item', {
                                    selected: key === selectedTab,
                                })}
                                onClick={() => {
                                    setSelectedTab((prevSelected) => (prevSelected !== key ? key : null));
                                    updateLayout();
                                }}
                            >
                                <div className="verticalTabPanel-icon">
                                    <MuIcon svg={tab.icon} />
                                </div>
                                <div className="verticalTabPanel-iconLabel">{tab.label}</div>
                            </button>
                        );
                    })}
                </div>
                {Content && (
                    <div className="verticalTabPanel-content">
                        <Content />
                    </div>
                )}
            </div>
        </div>
    );
};

export default injectLayoutUpdate(LeftPanel);
