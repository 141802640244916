import React from 'react';
import { MuSvg } from '../MuSvg';
import * as MuIcons from '../../../assets/icons/index';
import * as Icons from '../../../assets/icons/index';
import { SvgrComponent } from '../../Types/common';

type Props = {
    className?: string;
    svg?: SvgrComponent;
    svgName?: string;
};

const MuIcon: React.FC<Props> = ({ className, svg, svgName }: Props) => {
    let component = svg;
    if (!component && svgName) {
        component = (Icons as { [key: string]: SvgrComponent })[svgName];
        if (!component) {
            component = (MuIcons as { [key: string]: SvgrComponent })[svgName];
        }
    }

    if (!component) return null;

    return <MuSvg className={className} SvgComp={component} />;
};

export default MuIcon;
