import React, { useState } from 'react';
import { AvailableLocale, Profile } from 'mushin-redux-store';
import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import InfoPanel from '../../../../Components/InfoPanel/InfoPanel';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import MuIcon from '../../../../Components/MuIcon';
import ReactMultiEmail from '../../../../Components/ReactMultiEmail/ReactMultiEmail';
import LanguageSelect from '../../../../Components/Form/LanguageSelect';
import { addNotifSuccess } from '../../../../Redux/reducers/appNotificationsSlice';
import { useAppDispatch } from '../../../../Helpers/hooks';

type Props = React.PropsWithChildren<{
    handleSubmit: (emails: string[], locale: AvailableLocale) => Promise<Profile[]>;
    onClose: () => void;
}>;

const InviteProfilesModal: React.FC<Props> = ({ handleSubmit, onClose, children }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [locale, setLocale] = useState(i18n.resolvedLanguage as AvailableLocale);

    const dispatch = useAppDispatch();

    const submit = async () => {
        setLoading(true);
        if (emails.length) {
            try {
                const profiles = await handleSubmit(emails, locale);
                dispatch(addNotifSuccess(i18n.t('modals.inviteUsers.success', { count: profiles.length })));
                onClose();
            } catch (e) {
                console.error('Invite error', e);
                Sentry.captureException(e, {});
                setError(i18n.t<string>('modals.inviteUsers.error'));
            }
        }
        setLoading(false);
    };

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalHeader title={i18n.t('modals.inviteUsers.title')} icon="UserLine" />
            <ModalBody>
                {error && <InfoPanel classModifiers={['error']} iconName="Warning" text={error} />}
                <label className="mu-input__label">{i18n.t('modals.inviteUsers.language')}</label>
                <div className="mu-form-row">
                    <LanguageSelect value={locale} onChange={setLocale} disabled={loading} />
                </div>
                <label className="mu-input__label">{i18n.t('modals.inviteUsers.description')}</label>
                <div className="mu-form-row">
                    <ReactMultiEmail
                        placeholder={i18n.t('modals.inviteUsers.placeholder')}
                        emails={emails}
                        onChange={(newEmails) => {
                            setEmails(newEmails);
                        }}
                        getLabel={(email, index, removeEmail) => {
                            return (
                                <div key={index} className="react-multi-email__item">
                                    {email}
                                    <button
                                        className="react-multi-email__item-button"
                                        type="button"
                                        onClick={() => removeEmail(index)}
                                    >
                                        <MuIcon svgName="Close" />
                                    </button>
                                </div>
                            );
                        }}
                        enable={() => !loading}
                    />
                </div>
                {children}
            </ModalBody>
            <ModalFooter>
                <MuButton label={i18n.t('global.cancel')} handleClick={onClose} classModifiers={['large', 'default']} />
                <MuButton
                    disabled={!emails.length || loading}
                    label={`${i18n.t('global.confirm')}${emails.length ? ` (${emails.length})` : ''}`}
                    handleClick={submit}
                    classModifiers={['large']}
                    loading={loading}
                />
            </ModalFooter>
        </Modal>
    );
};

export default InviteProfilesModal;
