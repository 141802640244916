import {CancelToken} from 'axios';
import {ApiV3} from 'mushin-redux-store';

class UserRequests {
    static updateAvatar(userId: string, data: any, cancelToken?: CancelToken) {
        return ApiV3.getInstance().patch(`users/${userId}/avatar`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            cancelToken,
        });
    }
}

export default UserRequests;
