import React, { ReactNode, SVGAttributes } from 'react';
import { classWithModifiers, Modifiers } from '../../Helpers/css';
import { MuSvg } from '../MuSvg';
import MuIcon from '../MuIcon';
import Spinner from '../Spinner';

export type ButtonProps = {
    classModifiers?: Modifiers;
    className?: string;
    label?: ReactNode | string;
    rightIcon?: string | React.FC<SVGAttributes<SVGElement>>;
    icon?: string | React.FC<SVGAttributes<SVGElement>>;
    disabled?: boolean;
    loading?: boolean;
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit' | 'reset';
};

const MuButton: React.FC<ButtonProps> = ({
    classModifiers = [],
    className,
    label,
    rightIcon,
    icon,
    disabled = false,
    loading = false,
    handleClick,
    type = 'button',
}) => {
    const iconOnly = !!(icon || rightIcon) && !label;

    let fullClassName = classWithModifiers('mu-btn', classModifiers, className);

    if (iconOnly) {
        fullClassName += ` ${classWithModifiers('mu-icon-btn', classModifiers)}`;
    }

    return (
        <button type={type} className={fullClassName} disabled={disabled} onClick={handleClick}>
            {loading ? (
                <Spinner className="mu-btn__left-icon" size="16px" />
            ) : (
                icon && (
                    <>
                        {typeof icon === 'string' ? (
                            <MuIcon className="mu-btn__left-icon" svgName={icon} />
                        ) : (
                            <MuSvg className="mu-btn__left-icon" SvgComp={icon} />
                        )}
                    </>
                )
            )}

            {label && <span className="mu-btn__text">{label}</span>}
            {rightIcon && (
                <>
                    {typeof rightIcon === 'string' ? (
                        <MuIcon className="mu-btn__right-icon" svgName={rightIcon} />
                    ) : (
                        <MuSvg className="mu-btn__right-icon" SvgComp={rightIcon} />
                    )}
                </>
            )}
        </button>
    );
};

export default MuButton;
