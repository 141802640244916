import React, { useState } from 'react';
import { AvailableLocale, Profile, Project, useQuestionnaire, useQuestionnairesQuery } from 'mushin-redux-store';
import InviteProfilesModal from './InviteProfilesModal';
import EntitySelect from '../../../../Components/EntitySelect/EntitySelect';
import i18n from 'i18next';

type Props = {
    project: Project;
    handleSubmit: (
        emails: string[],
        locale: AvailableLocale,
        candidacyQuestionnaireId: string | null,
    ) => Promise<Profile[]>;
    onClose: () => void;
};

const InviteContributorProfilesModal: React.FC<Props> = ({ project, handleSubmit, onClose }) => {
    const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<string | null>(
        project.candidacy_questionnaire_id || null,
    );
    const questionnairesQuery = useQuestionnairesQuery({ project_id: project.id, candidacy: true }, true);

    return (
        <InviteProfilesModal
            handleSubmit={(emails, locale) => {
                return handleSubmit(emails, locale, selectedQuestionnaireId);
            }}
            onClose={onClose}
        >
            {questionnairesQuery.results?.length && (
                <>
                    <label className="mu-input__label">{i18n.t('modals.inviteUsers.answerQuestionnaire')}</label>
                    <EntitySelect
                        value={selectedQuestionnaireId}
                        options={questionnairesQuery.results}
                        useItem={useQuestionnaire}
                        onSelect={setSelectedQuestionnaireId}
                        getMoreResults={questionnairesQuery.getMoreResults}
                        placeholder={i18n.t('global.disabled')}
                        getEntityName={(questionnaire) => questionnaire.title}
                    />
                </>
            )}
        </InviteProfilesModal>
    );
};

export default InviteContributorProfilesModal;
