export const addShadowIfContainerIsScrolled = (scrollTop: number, idElement: string, className: string): void => {
    const elementWithScroll = document.getElementById(idElement);
    if (elementWithScroll) {
        const scrollClassName = `${className}--scroll-style`;
        if (scrollTop > 0) {
            elementWithScroll.classList.add(scrollClassName);
        } else {
            elementWithScroll.classList.remove(scrollClassName);
        }
    }
};

// --- Class modifiers ---

type ModifiersObject = {
    [modifierName: string]: boolean | null | undefined;
};

type Modifier = string | ModifiersObject | false | null | undefined;
export type ModifiersArray = ReadonlyArray<Modifier>;

export type Modifiers = ModifiersArray | Modifier;

export const classWithModifiers = (className: string, modifiers: Modifiers, optionalClassName?: string): string => {
    if (!modifiers && !optionalClassName) return className;

    const modifiersArray: ModifiersArray = Array.isArray(modifiers) ? modifiers : [modifiers];

    let classNames = className;

    if (optionalClassName) {
        classNames = `${optionalClassName} ${classNames}`;
    }

    for (const modifier of modifiersArray) {
        if (modifier) {
            if (typeof modifier === 'string') {
                classNames += ` ${className}--${modifier}`;
            } else {
                for (const key of Object.keys(modifier)) {
                    if (modifier[key]) {
                        classNames += ` ${className}--${key}`;
                    }
                }
            }
        }
    }

    return classNames;
};

type ClassName = string & {
    cls: (val: string | undefined) => ClassName;
    mod: (val: Modifier) => ClassName;
};

export const cls = (className: string): ClassName => {
    let originCls: string | undefined = className && `${className}`;

    const addCls = (val: string | undefined): ClassName => {
        if (val) {
            className += ` ${val}`;
        }
        originCls = val;

        return Object.assign(className, { mod, cls: addCls });
    };

    const mod = (val: Modifier): ClassName => {
        if (val && originCls) {
            if (typeof val === 'string') {
                className += ` ${originCls}--${val}`;
            } else {
                for (const key of Object.keys(val)) {
                    if (val[key]) {
                        className += ` ${originCls}--${key}`;
                    }
                }
            }
        }

        return Object.assign(className, { mod, cls: addCls });
    };
    return Object.assign(className, { mod, cls: addCls });
};
