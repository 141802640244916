import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useProject } from 'mushin-redux-store';
import i18n from 'i18next';
import EmptyZone from '../../Components/EmptyZone/EmptyZone';
import DataGrid from '../../Components/DataGrid/DataGrid';
import { BadgeComponent } from '../../Components/Badge/BadgeComponent';
import { useScrollThreshold } from '../../Helpers/effects';
import { buildProjectDefaultUrl } from '../../Helpers/navigator';
import { getStatus } from '../../Helpers/date';
import Client from '../../Services/Client';
import { openModal } from '../../Redux/reducers/modalsSlice';

type Props = {
    results: string[] | null;
    getMoreResults: () => void;
    selectedIds: string[];
    setSelectedIds: (ids: string[]) => void;
    totalItems?: number;
};

const ProjectsList: React.FC<Props> = ({ results, getMoreResults, selectedIds, setSelectedIds, totalItems }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ref = useScrollThreshold(getMoreResults, { deps: [results?.length] });

    if (!results?.length) {
        return Client.isAllowedToCreateProject() ? (
            <EmptyZone
                handleClick={() => dispatch(openModal('CreateProject'))}
                title={i18n.t('projects.empty.title')}
                subTitle={i18n.t('projects.empty.desc')}
            />
        ) : (
            <EmptyZone title={i18n.t('projects.empty.desc')} />
        );
    }
    return (
        <DataGrid
            standalone
            scrollRef={ref}
            columns={[
                {
                    key: 'label',
                    header: i18n.t('project.label'),
                    component: ({ item }) => <strong>{item.name}</strong>,
                    classModifiers: 'link',
                    onClick: (item) => navigate(buildProjectDefaultUrl(item)),
                    size: '4fr',
                },
                {
                    key: 'category',
                    header: i18n.t('project.category'),
                    component: ({ item }) =>
                        item.category ? <strong>{item.category}</strong> : <em>{i18n.t('project.noCategory')}</em>,
                    classModifiers: 'link',
                    onClick: (item) => navigate(`/projects?category=${item.category || null}`),
                    size: '2fr',
                },
                {
                    key: 'ownerName',
                    header: i18n.t('models.users.role.owner'),
                    component: ({ item }) => <>{`${item.owner.firstname} ${item.owner.lastname}`}</>,
                    size: '1fr',
                },
                {
                    key: 'status',
                    header: i18n.t('global.status.status'),
                    component: ({ item }) => (
                        <BadgeComponent status={getStatus(item.start_date, item.end_date, item.archived_at)} />
                    ),
                    size: 'minmax(100px, 1fr)',
                },
                {
                    key: 'creationDate',
                    header: i18n.t('components.filters.creationDate'),
                    component: ({ item }) => <>{moment(item.created_at).calendar()}</>,
                    classModifiers: 'date',
                    size: '1fr',
                },
            ]}
            data={results}
            getItem={useProject}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            totalItems={totalItems}
        />
    );
};

export default ProjectsList;
