import { AvailableLocale, locales } from 'mushin-redux-store';
import React from 'react';
import Select from '../Select/Select';

type Props = {
    value: AvailableLocale;
    onChange: (value: AvailableLocale) => void;
    mod?: 'contrast' | 'light';
    disabled?: boolean;
};

const LanguageSelect: React.FC<Props> = ({ value, onChange, mod, disabled }) => (
    <Select value={locales[value]} mod={mod} disabled={disabled}>
        {Object.keys(locales).map((localeKey) => (
            <button
                key={localeKey}
                type="button"
                className="mu-dropdown-menu__button-item"
                onClick={() => {
                    onChange(localeKey as AvailableLocale);
                }}
            >
                {locales[localeKey as AvailableLocale]}
            </button>
        ))}
    </Select>
);

export default LanguageSelect;
