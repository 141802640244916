import React from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteQuestionnaire, duplicateQuestionnaire, Questionnaire, updateQuestionnaire } from 'mushin-redux-store';
import i18n from 'i18next';
import moment from 'moment';
import { getQuestionnaireBaseUrl, hasStartedQuestionnaire, isQuestionnaireAdmin } from '../../Helpers/questionnaires';
import { addNotifAction, addNotifSuccess } from '../../Redux/reducers/appNotificationsSlice';
import { questionnaireStatsExport } from '../../Helpers/excel/questionnaireStatsExport';
import { openAlert } from '../../Redux/reducers/modalsSlice';
import { useAppDispatch } from '../../Helpers/hooks';
import ActionDropdownMenu from '../ActionDropdownMenu/ActionDropdownMenu';
import MuIcon from '../MuIcon';
import { getStatus } from '../../Helpers/date';

const EXPORT_QUESTIONNAIRE_ACTION_ID = 'EXPORT_QUESTIONNAIRE';

type Props = {
    className?: string;
    questionnaire: Questionnaire;
    hasSeeAnswersOption?: boolean;
    hasRespondentListOption?: boolean;
};

const QuestionnaireItemDropdown: React.FC<Props> = ({
    className,
    questionnaire,
    hasSeeAnswersOption,
    hasRespondentListOption,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const projectId = questionnaire.project_id;

    if (!isQuestionnaireAdmin(questionnaire)) return null;

    const status = getStatus(questionnaire.start_date, questionnaire.end_date, undefined, true);
    const questionnaireUrl = getQuestionnaireBaseUrl(questionnaire, projectId);

    return (
        <ActionDropdownMenu
            className={className}
            items={[
                {
                    icon: 'Show',
                    label: i18n.t('questionnaires.seeAnswers'),
                    handler: () => navigate(`${questionnaireUrl}/questions/${questionnaire.questions[0].id}/answers`),
                    hidden: !hasSeeAnswersOption,
                },
                {
                    icon: 'Cogwheel',
                    label: i18n.t('global.edit'),
                    handler: () => navigate(`${questionnaireUrl}/edit`),
                },
                {
                    icon: 'ResponsesMenu',
                    label: i18n.t('respondents.list'),
                    handler: () => navigate(`${questionnaireUrl}/respondents`),
                    hidden: !hasRespondentListOption,
                },
                {
                    icon: 'Duplicate',
                    label: i18n.t('questionnaires.duplicate'),
                    handler: async () => {
                        const newQuestionnaire = await dispatch(duplicateQuestionnaire(questionnaire.id));
                        navigate(`${getQuestionnaireBaseUrl(newQuestionnaire, projectId)}/edit`);
                    },
                },
                {
                    icon: 'Export',
                    label: i18n.t('questionnaires.export'),
                    handler: () =>
                        dispatch(
                            addNotifAction(
                                `${EXPORT_QUESTIONNAIRE_ACTION_ID}-${questionnaire.id}`,
                                i18n.t('questionnaires.export'),
                                async (setProgress) => {
                                    await dispatch(questionnaireStatsExport(questionnaire.id, setProgress));
                                    dispatch(addNotifSuccess(i18n.t('questionnaires.export_success')));
                                },
                            ),
                        ),
                    disabled: !questionnaire.respondent_count || !hasStartedQuestionnaire(questionnaire),
                },
                {
                    icon: 'Archive',
                    label: i18n.t('global.archive'),
                    handler: () =>
                        dispatch(
                            openAlert({
                                type: 'danger',
                                title: i18n.t('modals.archiveQuestionnaire.title', { name: questionnaire.title }),
                                icon: 'Archive',
                                confirmLabel: i18n.t('modals.archiveQuestionnaire.confirmButton'),
                                body: i18n.t('modals.archiveQuestionnaire.catchPhrase', { name: questionnaire.title }),
                                confirmAction: () =>
                                    dispatch(updateQuestionnaire(questionnaire.id, { archived_at: new Date() })),
                            }),
                        ),
                    disabled: !questionnaire.end_date || moment(questionnaire.end_date).isAfter(moment()),
                    hidden: !!questionnaire.archived_at,
                },
                {
                    icon: 'Archive',
                    label: i18n.t('global.unarchive'),
                    handler: () => dispatch(updateQuestionnaire(questionnaire.id, { archived_at: null })),
                    hidden: !questionnaire.archived_at,
                },
                {
                    icon: 'Box',
                    label: i18n.t('questionnaires.end'),
                    handler: () => dispatch(updateQuestionnaire(questionnaire.id, { end_date: new Date() })),
                    hidden: status !== 'inProgress',
                },
                {
                    icon: 'Delete',
                    label: i18n.t('global.delete'),
                    handler: () =>
                        dispatch(
                            openAlert({
                                type: 'danger',
                                title: i18n.t('modals.deleteQuestionnaire.title', { name: questionnaire.title }),
                                icon: 'Delete',
                                confirmLabel: i18n.t('modals.deleteQuestionnaire.confirmButton'),
                                body: i18n.t('modals.deleteQuestionnaire.catchPhrase', { name: questionnaire.title }),
                                confirmAction: () => dispatch(deleteQuestionnaire(questionnaire.id)),
                            }),
                        ),
                },
            ]}
            toggle={<MuIcon className="mu-survey-item__menu-icon" svgName="MoreOptions" />}
            preventDefault
        />
    );
};

export default QuestionnaireItemDropdown;
